<template>
    <div class="all" style="background:#F2F3F8">
        <!-- 联系客服 -->
        <!-- <div class="consult">
            <img src="../../assets/home/consult.png" alt="">
            <div>在线咨询</div>
        </div> -->
        <img v-if="open" @click="closeBox" class="close" src="../../assets/home/close.png" alt="">
        <!-- 头部内容 -->
        <!-- :style="{backgroundImage: 'url(' + homeMainImage + ')'}" -->
        <div class="header" >
            <div class="head-top">
                <div class="header-nav">
                    <Header :isActive = '1'></Header>
                </div>
                <div style="margin-top:80px;">
                    <div class="pt-line">临安国有资源经营性交易平台</div>
                    <div class="first-line">甄选国资优质资产，惠利民众幸福生活  </div>
                </div>
                <div >
                    <ul style="text-align: left;">
                        <li v-for="(item,index) in touristList"
                            v-bind:class="{active1:index==isActive}"
                            @click="checkedItem(index)"
                            class="rental-type">{{item.name}}
                        </li>
                    </ul>
                    <div v-if="isActive == 0" style="margin-top: 20px;text-align: left;">
                        <input class="home-input" v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" type="text" placeholder="请输入街道或大楼名称">
                        <div class="home-input-img" @click="$router.push('/map')"><img src="../../assets/home/map.png" alt=""><span class="home-input-img-text">地图</span></div>
                        <div @click="$router.push({name:'RentHouseSearch',params:{searchContent:searchContent,roomType:'Z'}})" class="home-input-icon"><img src="../../assets/home/search-icon.png" alt=""></div>
                    </div>
                    <div v-if="isActive == 1" style="margin-top: 20px;text-align: left;">
                        <input class="home-input" v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" type="text" placeholder="请输入街道或大楼名称">
                        <div class="home-input-img" @click="$router.push('/map')"><img src="../../assets/home/map.png" alt=""><span class="home-input-img-text">地图</span></div>
                        <div @click="$router.push({name:'LeaseShopSearch',params:{searchContent:searchContent,roomType:'S'}})" class="home-input-icon"><img src="../../assets/home/search-icon.png" alt=""></div>
                    </div>
                    <div v-if="isActive == 2" style="margin-top: 20px;text-align: left;">
                        <input class="home-input" v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" type="text" placeholder="请输入街道或大楼名称">
                        <div class="home-input-img" @click="$router.push('/map')"><img src="../../assets/home/map.png" alt=""><span class="home-input-img-text">地图</span></div>
                        <div @click="$router.push({name:'workRoomSearch',params:{searchContent:searchContent,roomType:'B'}})" class="home-input-icon"><img src="../../assets/home/search-icon.png" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="middleBox">
            <div class="middle-content ">
                <div class="item-box">
                    <img class="bg-item" src="../../assets/mode/house.png" alt="">
                    <div class="float-box" @mouseenter="mouseEnt('boxMain1',1)" @mouseleave="mouseOve('boxMain1',1)">
                        <div class="box-title">
                            <div class="box-tpl">
                                <img src="../../assets/mode/houseIcon.png" alt="">
                                <h3>房产资源</h3>
                            </div>
                            <div class="box-main" ref="boxMain1">
                                <div class="tpl-main">
                                    <ul class="list-box">
                                        <li @click="goHouse()">住宅</li>
                                        <li @click="goHouse(2)">商铺</li>
                                        <li @click="goHouse(3)">房产拍租</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="item-box">
                    <img class="bg-item" src="../../assets/mode/default.png" alt="">
                    <div class="float-box" @mouseenter="mouseEnt('boxMain2',2)" @mouseleave="mouseOve('boxMain2',2)">
                        <div class="box-title">
                            <div class="box-tpl">
                                <img src="../../assets/mode/defaultIcon.png" alt="">
                                <h3>其他资源</h3>
                            </div>
                            <div class="box-main" ref="boxMain2" >
                                <div class="tpl-main">
                                    <ul class="list-box" >
                                        <li @click="goHouse(3)">机动车</li>
                                        <li @click="goHouse(3)">机械设备</li>
                                        <li @click="goHouse(3)">股权</li>
                                    </ul>
                                    <ul class="list-box">
                                        <li  @click="goHouse(3)">砂石资源</li>
                                        <li @click="goHouse(3)">3C设备</li>
                                        <li @click="goHouse(3)">其他资产</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-box">
                    <img class="bg-item" src="../../assets/mode/canvass.png" alt="">
                    <div class="float-box" @mouseenter="mouseEnt('boxMain3',3)" @mouseleave="mouseOve('boxMain3',3)">
                        <div class="box-title">
                            <div class="box-tpl">
                                <img src="../../assets/mode/canvassIcon.png" alt="">
                                <h3>招商引资</h3>
                            </div>
                            <div class="box-main" ref="boxMain3">
                                <div class="tpl-main">
                                    <ul class="list-box" >
                                        <li  @click="goHouse(5)">产业园区</li>
                                        <li @click="open1()">自营综合体</li>
                                    </ul>
                                    <ul class="list-box">
                                        <li @click="open1()">商业股权</li>
                                        <li @click="open1()">投资融资</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-box ">
                    <img class="bg-item" src="../../assets/mode/coRich.png" alt="">
                    <div class="float-box" @mouseenter="mouseEnt('boxMain4',4)" @mouseleave="mouseOve('boxMain4',4)">
                        <div class="box-title">
                            <div class="box-tpl">
                                <img src="../../assets/mode/coRichIcon.png" alt="">
                                 <h3>共富家园</h3>
                            </div>
                            <div class="box-main"  ref="boxMain4">
                                <div class="tpl-main">
                                    <ul class="list-box">
                                        <li @click="open1()">景区门票</li>
                                        <li @click="open1()">酒店民宿</li>
                                        <li @click="open1()">临安美景</li>
                                    </ul>
                                    <ul class="list-box">
                                        <li @click="goHouse(4)">共富产品</li>
                                        <li @click="open1()">家政服务</li>
                                        <li @click="open1()">维修服务</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part-one">
                <div  class="part-one-external-box">
                    <div class="part-one-second-inside-box">
                        <div class="part-one-video">
                            <video :src="homeVideo" muted="muted" controls="controls" autoplay="autoplay" loop="loop" type="video/mp4" width="100%" height="100%"></video>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <!-- 身体内容 -->
        <div class="tbody">
            <div class="part-two">
                <div class="part-one-external-box" id="box1">
                    <div class="body-header" >
                        <div class="body-top">
                            <span class="top-head">房产租赁</span> 
                            <span class="title">为您推荐</span>
                            <div style="display: flex;cursor:pointer;align-items: baseline;" @click="savePoint('box1','/rentHouseSearch')">
                                <p class="cat-more">共有房源{{houseNum}}套</p>
                                <img class="more-icon" src="../../assets/home/right.png" alt="">  
                            </div>
                        </div>
                    </div>
                    <div class="body-picture">
                        <ul class="part-three-ul">
                            <li v-for="(item) in houseList" class="part-three-li" style="height: auto;">
                                <div @click="savePoint('box1','/rentHouseDetails',{roomId: item.roomId,tplType:item.rentalType})">
                                    <div style="height: 100%;">
                                        <div ><img class="part-three-li-img part-two-img" :src="item.showPictures" alt=""></div>
                                        <div class="picture-ul-div-first nameList" :title=nameFormat(item.estateName,item.roomName)>{{item.estateName}}/{{item.roomName}}</div>
                                        <div>
                                            <div class="describe" >{{item.roomType}}</div>
                                            <div class="company">元/月</div>
                                            <div class="price">{{item.roomPrice}}</div>
                                            
                                        </div>
                                    </div>
                                    <!-- <div><img style="width:270px" src="../../assets/home/model.png" alt=""></div> -->
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="part-there">
                <div class="part-four-first-inside-box part-one-external-box" id="box2">
                    <div class="body-header part-four-first-inside-box" >
                        <div class="body-top">
                            <span class="top-head">拍租拍卖</span> 
                            <span class="title">为您推荐</span>
                            <div style="display: flex;cursor:pointer;align-items: baseline;"  @click=" savePoint('box2','/rentAuctionList')">
                                <p class="cat-more">共有房源{{constNum}}套</p>
                                <img class="more-icon" src="../../assets/home/right.png" alt="">  
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul class="part-three-ul auction-ul">
                            <li v-for="item in auctionList1" class="part-three-li " style="position: relative;">
                                <div  @click="ceshi(item.auctionId)">
                                    <div style="height:100%;display: flex;">
                                        <div class="link-first-box" >
                                            <img class="part-three-li-img" :src="item.picUrl" alt="">
                                        </div>
                                        <div class="link-second-box">
                                            <div class="link-second-box-one">{{item.title}}</div>
                                            <div class="link-second-box-two tplLine">
                                                <span class="area-text">面积</span>
                                                <span class="area-number" style="margin-right: 0;">{{item.constructionArea?item.constructionArea+'㎡':'无'}}</span>
                                            </div>
                                            <div class="tplLine">
                                                <div class="describe">当前价格</div>
                                                <div class="company">元</div>
                                                <div class="price">{{item.currentPrice / 100}}</div>
                                                
                                            </div>
                                            <div class="link-second-box-three tplLine">
                                                <span class="time-text">截止时间</span>
                                                <span class="time-number">{{item.endTime}}</span>
                                            </div>
                                            <div class="link-third-box">
                                                <div class="watch"><span class="sign-text mr-r-5">{{item.lookUserNum}}</span>次围观</div>
                                                <div class="sign"><span class="sign-text mr-r-5">{{item.applyCount}}</span>人报名</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div v-if="item.newStatusCode == 2" class="auction-tips1">正在进行</div>
                                <div v-if="item.newStatusCode == 1" class="auction-tips2">即将开始</div>
                                <div v-if="item.newStatusCode == 3" class="auction-tips3">已结束</div>
                                <div v-if="item.newStatusCode == -6" class="auction-tips3">暂缓</div>
                                <div v-if="item.newStatusCode == -5" class="auction-tips3">撤拍</div>
                                <div v-if="item.newStatusCode == -4" class="auction-tips3">中止</div>
                                <div v-if="item.newStatusCode == -3" class="auction-tips3">撤回</div>
                                <div v-if="item.newStatusCode == -2" class="auction-tips3">流拍</div>
                                <div v-if="item.newStatusCode == -1" class="auction-tips3">取消</div>
                                <div v-if="item.newStatusCode == 0" class="auction-tips3">未发布</div>
                            </li>    
                        </ul>
                    </div>
                </div>
            </div>
            <div class="part-five">
                <div class="part-three-second-inside-box part-one-external-box" id="box3">
                    <div class="body-header" >
                        <div class="body-top">
                            <span class="top-head">招商引资</span> 
                            <span class="title">为您推荐</span>
                            <div style="display: flex;cursor:pointer;align-items: baseline;"  @click=" savePoint('box3','/industrialParkSearch')">
                                <p class="cat-more">查看更多</p>
                                <img class="more-icon" src="../../assets/home/right.png" alt="">  
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul class="part-three-ul-second">
                            <!-- :style="{backgroundImage: 'url(' + item.industryPic + ')'}" -->
                            <li v-for="item in parkList" class="part-three-li-second">
                                <router-link class="link" to="/" @click.native="savePoint('box3','/industrialParkDetails',{ roomId: item.id})">
                                    <div class="part-three-li-second-box" :style="{backgroundImage: 'url(' + item.industryPic + ')',backgroundSize: '100% 100%'}">
                                        <div class="three-li-second-box-one"></div>
                                        <div class="three-li-second-box-two">
                                            <div class="park-name">{{item.industryName}}</div>
                                            <div class="park-adress"><img src="../../assets/home/Mask (10).png" alt=""><span style="margin-left:5px">{{item.location}}</span></div>
                                            <div class="park-type"><img src="../../assets/home/Mask (9).png" alt=""><span style="margin-left:5px">{{item.work}}</span></div>
                                        </div>
                                    </div>    
                                </router-link>
                                
                            </li>
                        </ul>
                    </div>
                </div>
                <div class=" part-one-external-box" id="box4">
                    <div class="body-header part-four-first-inside-box" >
                        <div class="body-top">
                            <span class="top-head">文化旅游</span> 
                            <span class="title">为您推荐</span>
                            <div style="display: flex;cursor:pointer;align-items: baseline;"  @click=" savePoint('box4','/culturalTourismList')">
                                <p class="cat-more">查看更多</p>
                                <img class="more-icon" src="../../assets/home/right.png" alt="">  
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul class="tpl-part-ul part-three-ul auction-ul">
                            <li v-for="item in travelList"  class="part-three-li " style="position: relative;">
                                <div  >
                                    <div class="part-box">
                                        <div class="box-left">
                                            <img :src="item.plan" alt="">
                                        </div>
                                        <div class="box-right">
                                            <div class="box-content">
                                                <h3>{{item.attractionName}}</h3>
                                                <p>{{item.introduce}}</p>
                                            </div>
                                            <div class="box-btn" @click="savePoint('box4','/culturalTourismDetails', {id: item.id})">查看更多</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="part-there">
                <div id="box5" class="part-one-external-box">
                    <div class="body-header" >
                        <div class="body-top">
                            <span class="top-head">共富家园</span> 
                            <span class="title">为您推荐</span>
                            <div style="display: flex;cursor:pointer;align-items: baseline;"  @click=" savePoint('box5','/shoppingList')">
                                <p class="cat-more">查看更多</p>
                                <img class="more-icon" src="../../assets/home/right.png" alt="">  
                            </div>
                        </div>
                    </div>
                    <div class="body-picture">
                        <ul class="picture-ul">
                            <li class="picture-li" v-for="(item) in goodList">   
                                <router-link class="link" to="/" @click.native="savePoint('box5','/shoppingMall', {id: item.id})">
                                    <!-- <div><img style="width:270px" src="../../assets/home/model.png" alt=""></div> -->
                                    <div style="text-align: center;padding-top: 15px;"><img style="width:250px;height: 202px;" :src="item.image" alt=""></div>
                                    <div class="picture-ul-div-first">
                                        <p style="margin-left: 10px;" class="nameList">{{item.title}} </p>
                                        <span style="float: right;margin-right: 20px;color: red;"> <i style="font-size: 20px;">{{(item.price/100).toFixed(2)}}</i> <i>元</i></span>  
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- 浮框内容 -->
        <div v-if="open" class="floating-window">
            <img class="window-first-img" src="../../assets/home/phone.png" alt="">
            <div class="text-box">
               
                <div class="window-text1">
                    <img src="../../assets/home/gzLogo.png" alt="" />
                    <span style="font-weight: 200;">——</span>  临安国有资源经营性交易平台小程序</div>
                <div class="window-text2">甄选国资优质资产</div>
                <div class="window-text3">惠利民众幸福生活</div>
            </div>
            <div class="text-box2">
                <div>微信扫码  <img src="../../assets/home/rightArrow.png" alt=""></div>
                <div>立即登录</div> 
            </div>
            <img class="window-second-img" src="../../assets/home/qrCode.png" alt="">
        </div>
        <!-- 尾部内容 -->
        <div style="background:#414143;width:100%;min-width: 1520px;height:220px;">
            <div style="width: 1200px;margin: 0 auto;text-align: center; ">
                <div style="display: flex;">
                    <div >
                        <div class="first">
                            <div class="first-text">友情链接</div>
                            <ul class="first-text-ul active-li">
                                <li><a href="http://www.linan.gov.cn" target="_blank">杭州市临安区人民政府</a></li>
                            </ul>
                        </div>
                        <div class="first" >
                            <div class="first-text">联系我们</div>
                            <ul class="first-text-ul">
                                <li>地址：浙江省杭州市临安区锦北街道武肃街1636号2幢整幢7楼701-705</li>
                                <li>联系方式：13588181723</li>
                            </ul>
                        </div>
                    </div>
                    <div >
                        <div class="first-end" style="margin-right: 30px;">
                            <img class="qrCode" src="../../assets/home/1.png" alt="">
                            <div class="word">临安城投集团</div>
                        </div>
                        <div class="first-end" style="margin-right: 30px;">
                            <img class="qrCode" src="../../assets/home/2.jpg" alt="">
                            <div class="word">临安新锦集团</div>
                        </div>
                        <div class="first-end" style="margin-right: 30px;">
                            <img class="qrCode" src="../../assets/home/3.jpg" alt="">
                            <div class="word">临安国兴集团</div>
                        </div>
                        <div class="first-end" style="margin-right: 30px;">
                            <img class="qrCode" src="../../assets/home/4.jpg" alt="">
                            <div class="word">临安科投集团</div>
                        </div>
                        <div class="first-end" >
                            <img class="qrCode" src="../../assets/home/qrCode.png" alt="">
                            <div class="word">国资小临小程序</div>
                        </div>
                    </div>
                    
                </div>
                <div class="word" @click="goGov()" style="width: 100%;cursor: pointer;margin-top:15px;">浙ICP备2022033234号-3</div>
            </div>
            
        </div>
    </div>
</template>
<script>
    import Header from '../../components/header.vue'
    import request from '../../api/index'
import router from '../../router'
    export default {
        name: "Home",
        data() {
            return {
                currentIndex: -1,
                goodList:[],//商品列表
                open:'',
                houseList:'',
                auctionList:[],
                houseNum:0,
                constNum:0,
                auctionList1:[],
                parkList:'',
                travelList:'',
                data2:'',
                activeName: 'first',
                searchContent:'',
                homeMainImage:'',
                homeVideo:'',
                introduce:'',
                timer:'',
                imgurl:'',
                only:'',
                isActive:0,
                url:'https://zc-item.taobao.com/auction.htm',
                touristList:[
            {
                name:"租房",
                id:0
            },
            {
                name:"商铺",
                id:1
            },
            {
                name:"办公用房",
                id:2
            },
            ], 
            }
        },
        created(){
            
        },
        mounted() {
            this.getGoods()
            this.getHomePageInformation()
            this.getRentList()
            
            // this.getStockExchangeList()
            this.getVideo()
            if(localStorage.getItem('status') == 'false'){
                this.open = false
            }else{
                this.open = true
            }
            window.onbeforeunload = function (e) {
                var storage = window.localStorage;
                storage.removeItem('status')
            }
           
        },
        // beforeRouteLeave(to,from,next){
        //     to.meta.keepAlive = true
        //     next()
        // },
        methods: {
            mouseEnt(dom,num){
                this.$refs[`${dom}`].style.opacity = '1'
                this.$refs[`${dom}`].style.height = 'auto'
                this.currentIndex = num
            },
            mouseOve(dom){
                this.$refs[`${dom}`].style.opacity = '0'
                this.$refs[`${dom}`].style.height = '0'
            },
            savePoint(ele,path,param={}){
                if(param.tplType == 'SP'){
                    path = '/leaseShopDetails'
                }
                if(param.tplType == 'BG'){
                    path = '/workRoomDetails'
                }
                this.$router.push({
                    path:path,
                    query:param
                })
                localStorage.setItem('tplPoint',ele)
            },
            nameFormat(name,name1){
                return (name+'/'+name1)
            },
            goHouse(type=1){
                switch (type){
                    case 1:
                        this.$router.push('/rentHouseSearch')
                        break;
                    case 2:
                        this.$router.push('/leaseShopSearch')
                        break;
                    case 3:
                        window.open('https://zc-paimai.taobao.com/zc/zc_item.htm?user_id=2214766086648&track_id=815af5b9-ea75-467d-9650-0be6402500ee')
                        break;
                    case 4:
                        this.$router.push('/shoppingList')
                        break;
                    default:
                        this.$router.push('/industrialParkSearch')
                        break
                }
            },
            open1() {
                this.$message('还在开发中');
            },
            
            stop(){
                clearInterval(this.timer);

            },
            goGov(){
                window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
            },
            visitfun(index){
                
                this.isvisited=index;
                console.log(this.isvisited)
            },
            getHomePageInformation(){
                let params = {   
                    }
                    request.get('/operating-real-estate/home/NewHome',params)
                    .then(
                        res=>{
                            console.log(777,res.result);
                            if (res.result == 0) {
                             this.houseNum = res.data.rentListCount
                             this.houseList = res.data.rentList
                             console.log(this.houseList)
                             this.parkList = res.data.investmentList
                             this.travelList = res.data.travelList
                            }
                        }
                    );
            },
            //商品列表
            getGoods(){
                let params = {
                    pageNum:1,
                    pageRow:10
                }
                request.get('/api/product/list',params).then(res=>{
                    console.log(777,res);
                    this.goodList = res.data.list
                })
            },
            // 阿里数据 
            getRentList(){
                let params = {
                    "pageNum":1,
                    "pageRow":4
                }
                request.post('/operating-real-estate/home/aliAuctionList',params)
                            .then(
                                res=>{
                                    if (res.result == 0) {
                                        console.log("kankan",res.data.list);
                                        this.constNum = res.data.totalCount
                                        if(res.data.list.length != 0){
                                            if(this.auctionList1.length>0){
                                                this.auctionList1.concat(res.data.list)
                                                if(this.auctionList1.length > 4){
                                                    this.auctionList1 = this.auctionList1.slice(0,4)
                                                }
                                           }else {
                                                this.auctionList1 = res.data.list.slice(0,4)
                                           }
                                        }
                                        
                                    }
                                }
                            );
            },
            // 产交所数据
            getStockExchangeList(){
                // let params = {
                //     "pageNum":1,
                //     "pageRow":2
                // }
                // request.post('/operating-real-estate/home/subjectInfo',params)
                //             .then(
                //                 res=>{
                //                     if (res.result == 0) {
                //                         if(res.data.list.length != 0){
                //                            if(this.auctionList1.length>0){
                //                             this.auctionList1.concat(res.data.list)
                //                             if(this.auctionList1.length > 4){
                //                                     this.auctionList1 = this.auctionList1.slice(0,4)
                //                                 }
                //                            }else {
                //                             this.auctionList1 = res.data.list.slice(0,4)
                //                            }
                //                         }
                                        
                //                     }
                //                 }
                //             );
            },
            ceshi(id){
                window.open(this.url + "?spm=a213w.7629120.projectDetail.4.5e185c459WTKZB" + "&id=" + id, '_blank')
                // window.location.href = this.url + "?spm=a213w.7629120.projectDetail.4.5e185c459WTKZB" + "&id=" + id;
            },
            ceshi1(url){
                window.open(url)
                
            },
            getVideo(){
               let params = {   
                    }
                    request.get('/operating-real-estate/home/HomeMainImage',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.homeMainImage = res.data.homeMainImage;
                                this.homeVideo = res.data.homeVideo;
                                this.introduce = res.data.introduce;
                            }
                        }
                    ); 
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            closeBox(){
                this.open = false
                localStorage.setItem('status', false)
            },
            checkedItem(index){
                this.isActive=index;
                this.divShow = index;
            },
        },
        components:{
            Header,
        }
    }
</script>
<style lang="less" scoped>
.middleBox {
    width: 100%;
    background: #fff;
    padding-bottom: 2%;
    .middle-content {
        padding: 2% 0;
       
        display: flex;
        width: 1200px;
        margin: 0 auto;
        align-content: space-between;
        .item-box {
            position: relative;
            flex: 1;
            .bg-item{
                width: 100%;
                height: 100%;
            }
            .float-box  {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                background: rgba(0, 0, 0, 0.3);
                color: #fff;
                justify-content: center;
                align-items: center;
                .box-title {
                    .box-tpl {
                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                            vertical-align: text-top;
                        }
                        h3,img{
                            display: inline-block;
                        }
                    }
                    .box-main {
                        opacity: 0;
                        height: 0;
                        overflow: hidden;
                        transition: opacity 0.3s ease-in-out, height 0.5s ease-in-out;
                        .tpl-main {
                            display: flex;
                            li{
                                cursor: pointer;
                                background: rgba(0, 0, 0, 0.5);
                                color: #fff;
                                text-align: center;
                                width: 120px;
                                // border: 1px solid #fff;
                                margin: 20px 10px;
                                padding: 5px 3px;
                            }
                            li:hover{
                                background-color: #EAF4FF;
                                color:#333333 
                            }
                        }
                    }
                }
            }
        }
        
    }
}
.middle-content .item-box:first-child .float-box{
    border-radius:16px 0 0 16px;
}
.middle-content .item-box:last-child .float-box {
    border-radius:0 16px 16px 0;
}

.picture-li:hover,.part-three-li:hover,.part-three-li-second-box:hover{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
     transform: scale(1.05);
}
.float-box:hover {
    background: rgba(0, 0, 0, .5) !important;
}

//文化旅游模块样式
.tpl-part-ul {
    
    li{
        background-color: #ECECEC;
        width: 45% !important;
        height: 240px !important;
        overflow: hidden;
        .part-box {
            height:100%;
            display: flex;
           
            position: relative;
            .box-left {
                img {
                    width: 245px;
                    height: 240px;
                }
            }
            .box-right {
                
                margin-left: 20px;
                h3{
                    padding: 0;
                    margin-bottom: 30px;
                    font-size: 18px;
                    margin-top: 0;
                }
                p {
                    max-height: calc(20px * 6); /* 1.2em 为每一行的高度，乘以 4 表示最多显示 4 行 */
                    line-height: 20px; /* 设置行高，与上面的计算一致 */
                    overflow: hidden; /* 隐藏超出容器高度的部分 */
                    text-overflow: ellipsis; /* 使用省略号代替超出的部分 */
                    font-size: 14px;
                    display: -webkit-box; /* 兼容性设置 */
                    -webkit-box-orient: vertical; /* 兼容性设置 */
                    -webkit-line-clamp: 6; /* 设置最大行数 */
                }
                .box-btn {
                    position: absolute;
                    cursor: pointer;
                    bottom: 10px;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 20px;
                    background-color: #3094FF;
                    color: #fff;
                    right: 10px;
                }
            }
        }
    }
}

.auction-ul {
    // display: flex;
    li{
        width: 45% !important;
    }
}
.auction-ul>li:nth-child(1),.auction-ul>li:nth-child(2){
    margin-bottom: 30px;
}

i{
    font-style: normal;
}
.all{
    min-width: 1520px;
}
/deep/.el-popover{
    padding: 0px;
}
.el-tabs__nav-wrap::after {
  position: static !important;
}

.el-tabs__item {
    // width: 28px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF !important;
    line-height: 14px;
    margin-bottom: 10px;
}
.el-tabs__active-bar {
    // background: #FFFFFF;
    border-radius: 2px;
}
// 首页头部搜索
.rental-type{
    display: inline-block;
    margin-right: 30px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #C9C9C9;
    line-height: 14px;
    cursor: pointer;
    
}


.active1{
    border-bottom: 4px solid white;
    padding-bottom: 8px;
    border-radius: 2px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 14px; 
}
.home-input{
    width: 500px;
    height: 52px;
    outline: none;
    border: none;
    border-radius: 5px 0px 0px 5px;
    vertical-align: middle;
    font-size: 16px;
    padding-left: 25px;
}
.qrCode {
  width: 110px;
  height: 110px;
  border-radius: 5px;
}
.home-input-img{
    display: inline-block;
    width: 100px;
    height: 54px;
    background: #FFFFFF;
    vertical-align: middle;
    cursor:pointer;
    text-align: center;
}
.home-input-img > img{
    vertical-align: middle;
    margin-right: 5px;
}
.home-input-img-text{
    display: inline-block;
    width: 28px;
    height: 54px;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #333333;
    line-height: 54px;
}
.home-input-icon{
    display: inline-block;
    width: 100px;
    height: 54px;
    line-height: 64px;
    background: #3094FF;
    vertical-align: middle;
    border-radius: 0px 4px 4px 0px;
    cursor:pointer;
    text-align: center;
}
    

    /* 咨询 */
    // .consult{
    //     width: 180px;
    //     height: 50px;
    //     line-height: 50px;
    //     background: red;
    //     position: fixed;
    //     right: 0;
    // }
    /* 关闭 */
    .close{
       position: fixed;
        bottom: 200px;
        right: 300px; 
        background-color: #999;
        border-radius: 50%;
    }
    // .consult > div{
    //     display: inline-block;
    //     vertical-align: middle;
    // }
    // .consult > img{
    //     vertical-align: middle;
    // }
    /* 头部内容 */
    .header{
        width: 100%;
        height: 570px;
        margin: 0 auto;
        /* background-color: blue; */
        background-image: url(../../assets/home/background-img.png);
        background-size: 100% 570px;
        min-width: 1500px;
    }
    /* 头部导航 */
    .head-top {
        width: 1200px;
        text-align: left;
        margin: 0 auto;
    }
    .header-nav{
        width: 100%;
        height: 70px;
    }
    .nav-ul,.picture-ul, ul{
        margin: 0;
        padding: 0;
        list-style:none;
    }
   
    .nav-li-btn{
        background: none;
        border: none;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    /* 头部宣传语 */
    
    .first-line,.pt-line{
        
        // width: 751px;
        height: 56px;
        font-size: 40px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 56px;
        letter-spacing: 4px;
        margin: 0px 0px 50px 0px;
    }
    .pt-line {
        font-size: 25px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        margin: 0;
    }
    .second-line{
        width: 717px;
        height: 50px;
        font-size: 50px;
        font-family:方正舒体;
        color: #FFFFFF;
        line-height: 57px;
        margin-left: 552px;
    }  
/* 主体 */
.tbody {
    width:100%;
    background-color:#F2F3F8;
    text-align: left;   
}
.part-one-li{
    display: inline-block;
    width: 150px;
    height: 200px;
    border-radius: 6px;
    margin-right: 40px;
}
.part-five {
    background-color: #fff;
    padding: 50px 0;
}
.part-two,.part-there{
    margin: 50px 0;
}
.part-four-first-inside-box {
    margin-top: 50px;
}
.part-one-li > img{
    margin: 35px 30px 25px 30px;
}
.part-one-li > div{
    text-align: center;
    height: 18px;
    font-size: 18px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
}
.part-one-external-box{
    width: 1200px;
    margin: 0 auto;
}
.part-one-second-inside-box{
    // margin-top: 100px;
    display: flex;
    flex-direction: row;
}

.active-li a:hover{
    color: #fff;
}


.part-two-ul > li{
    display: inline-block;
    width: 270px;
    height: 275px;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 100px 20px 30px 0px;
}
.part-two-li > img{
    width: 190px;
    height: 171px;
    margin: 30px 40px;
}
.part-two-li > div{
    // width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    text-align: center;
}
.part-three-ul > li{
    display: inline-block;
    width: 240px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    margin-right: 35px;
    padding: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
}
.part-three-li-img{
    width:245px;
    margin: 0 auto;
    display: block;
    height:240px;
   
}
.part-two-img {
    width: 240px;
    height: 136px;
}
.link-first-box{
    height:auto;
    position: relative;
}
.auction-tips1{
    background-image: url(../../assets/home/underway.png);
}
.auction-tips2{
    background-image: url(../../assets/home/start.png);
}
.auction-tips3{
    background-image: url(../../assets/home/over.png);
}
.auction-tips1,.auction-tips2,.auction-tips3{
    width: 70px;
    height: 25px;
    font-size: 12px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0  ;
}

.link-second-box{
    width:250px;
    height:auto;
    position: relative;
    margin-left: 25px;
    margin-top: 10px;
    .tplLine {
        margin-bottom: 10px;
    }
}
.link-second-box-one{
    // width: 119px;
    height: auto;
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    margin: 0;
    margin-bottom: 20px;
}
.link-second-box-two,.link-second-box-three{
    margin:0
}
.area-text{
    width: 28px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-right: 16px;
}
.area-number{
    // width: 40px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    float: right;
    margin-right: 8px;
}
.time-text{
    width: 56px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-right: 8px;
}
.time-number{
    // width: 93px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    float: right;
}
.link-third-box{
    position: absolute;
    bottom: 0;
    width: 260px;
    height: 42px;
    border-top: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    color: #333;
    background-color: #F2F3F8;
}

.watch,.sign{
    flex: none;
    text-align: center;
    width: 50%;
    height: 42px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 42px;
}
.watch{
    border-right:none;
}
.sign-text{
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 600;
    color: #333333;
    line-height: 14px;
}
.part-three-li-second{
    display: inline-block;
    width: 375px;
    height: 280px;
    margin-right: 10px;
}
.part-three-li-second-box{
    width: 375px;
    height: 280px;
    transition: all 0.3s ease;
    // background-image: url(../../assets/home/风景介绍.jpeg);
}
.three-li-second-box-one{
    width: 100%;
    height: 180px;
}
.three-li-second-box-two{
    height: 90px;
    background: #000000;
    opacity: 0.5;
    padding-top: 10px;
}
.park-name{
    width: auto;
    height: 18px;
    font-size: 18px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 18px;
    margin: 0px 0px 20px 15px;
}
.park-adress,.park-type{
    // width: 120px;
    height: 12px;
    font-size: 12px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 12px;
    margin: 0px 0px 10px 15px;
}

.body-header{
    width: 100%;
    height: auto;
    .body-top {
        P{
            margin-top: 0;
            padding-top: 0;
        }
        .top-head {
            font-size: 30px;
            color: #333333;
            font-weight: bold;
            font-family: PingFang-SC-Semibold, PingFang-SC;
        }
        .title {
            margin-left: 30px;
            font-weight: 300;
            color: #333333;
            font-size: 30px;
            font-family: PingFangSC-Thin, PingFang SC;
        }
        img {
            margin-top: 25px;
            width: 16px;
            height: 12px;
            vertical-align: middle;
        }
    }
   
}
// .body-header>div{
//     display: inline-block;
//     width:auto;
//     height: 30px;
//     font-size: 30px;
//     font-family: PingFang-SC-Semibold, PingFang-SC;
//     font-weight: 600;
//     color: #333333;
//     line-height: 30px;
// // }
// .body-header > span {
//     width: 32px;
//     height: 30px;
//     font-size: 16px;
//     font-family: PingFang-SC-Regular, PingFang-SC;
//     font-weight: 400;
//     color: #333333;
//     line-height: 30px;
//     float: right;
//     vertical-align: middle;
// }
// .body-header > img{
//     float: right;
//     margin: 7px 0;
//     vertical-align: middle;
// }

.picture-li{
    width: 270px;
    display: inline-block;
    border-radius: 16px;
    margin: 0px 20px 0px 0px;
    transition: all 0.3s ease;
    box-sizing: border-box;
    background: #FFFFFF;
}
.nameList {
    width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.picture-ul-div-first{
    height: 40px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    margin-top: 20px;
}
.describe{
    display: inline-block;
    width: 100px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin: 0;
}
.price{
    display: inline-block;
    // width: 39px;
    height: 20px;
    font-size: 20px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #E76565;
    line-height: 20px;
    float: right;
    margin-right: 5px;
}
.company{
    display: inline-block;
    // width: 30px;
    height: 20px;
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #E76565;
    line-height: 20px;
    float: right;
    margin-right: 0;
}
/* 浮窗部分 */
.floating-window{
    width: 100%;
    padding-top: 0px;
    height: 200px;
    background: #55BA86;
    position: fixed;
    bottom: 0;
    right: 0;
}
.window-first-img{
    position: relative;
    width: 320px;
    height: 260px;
    right: 80px;
    top: -60px;
}
.window-second-img{
    position: relative;
    left: 80px;
    width: 150px;
    bottom: 100px;
    border-radius: 5px;
}

.text-box{
    display: inline-block;
    position: relative;
    right: 0;
    bottom: 90px;
}
.text-box2{
    display: inline-block;
    position: relative;
    left: 50px;
    bottom: 100px;
    width: 100px;
    height: 50px;
    font-size: 18px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
    img{
        position: absolute;
        width: 11px;
        height: 20px;
        top: 3px;
        right: -10px;
    }
}
.window-text1{
    height: 20px;
    font-size: 24px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 20px;
    margin-bottom: 30px;
    img {
        width: 134px;
        height: 35px;
        margin-right: 10px;
        margin-left: -50px;
        vertical-align: middle;
        display: inline-block;
    }
}
.window-text2{
    height: 50px;
    font-size: 36px;
    font-family: PingFang-SC-Light, PingFang-SC;
    font-weight: 300;
    text-align: left;
    color: #FFFFFF;
    line-height: 50px;
}
.window-text3{
    text-align: right;
    height: 50px;
    font-size: 36px;
    font-family: PingFang-SC-Light, PingFang-SC;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 50px;
}
/* 底部 */
.first,.first-picture{
    text-align: left;
    margin-right: 120px;
    vertical-align: top;
}
.first-picture{
    margin-top: 30px;
    text-align: center;
}
.first-end{
    display: inline-block;
    text-align: center;
    margin:20px 0px 0px 0px;
    
}
.first-text{
    width: 56px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 14px;
    margin: 20px 86px 20px 0px;
}
a{
    width: 120px;
    height: 12px;
    font-size: 12px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    text-decoration:none;
    margin-bottom: 15px;
}
.first-text-ul > li{
    height: 12px;
    font-size: 12px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    margin-bottom: 15px;
}
.word{
    width: 101px;
    height: 12px;
    margin-top: 10px;
    font-size: 12px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
}
</style>
